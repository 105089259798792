<template>
  <el-card shadow="never">
    <template #header>
      <el-page-header @back="goBack">
        <template #content>
          <span class="text-large font-600 mr-3"> 成员列表 </span>
        </template>
        <template #extra>
          <div class="flex items-center">
            <el-button @click="add" type="primary">添加</el-button>
          </div>
        </template>
      </el-page-header>
    </template>
    <div>
      <el-form inline label-width="120px">
        <el-form-item label="关键词">
          <el-input v-model="words" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="sreach">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
  <el-card shadow="never" style="margin-top:20px">
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="id" label="id" width="55" />
      <el-table-column label="会员信息" width="300">
        <template #default="scope">
          <img :src="(scope.row.avatar ? scope.row.avatar : '../../assets/img/avatar.jpg')" style="width:40px;height:40px;display: inline-block;float: left;margin-right: 10px;"/>
          <span style="line-height:40px;display: inline-block;">{{scope.row.realname}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机号码" width="110" />
      <el-table-column prop="jtname" label="军团" width="110" />
      <el-table-column prop="levelname" label="职位" width="110" />
      <el-table-column prop="bwname" label="班委" width="110" />
      <el-table-column label="公司" >
        <template #default="scope">
          <p>{{scope.row.company}}</p>
          <p>{{scope.row.position}}</p>
          <p>{{scope.row.industryName}}</p>
          <p>{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.areaName}}{{scope.row.address}}</p>
        </template>
      </el-table-column>
      <el-table-column prop="addTime" label="添加时间" width="160"/>
      <el-table-column label="状态" width="160">
        <template #default="scope">
          <p v-if="scope.row.status==2">审核不通过</p>
          <p v-else-if="scope.row.status==1">正常</p>
          <p v-else>待审核</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="220">
        <template #default="scope">
          <el-button v-if="scope.row.status==0" type="warning" size="small" @click="examine(scope.row.id)">审核</el-button>
          <el-button size="small" @click="edit(scope.row.id)">编辑</el-button>
          <el-button
            size="small"
            type="danger"
            @click="del(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="float:right;margin:20px">
      <el-pagination
        v-model:page-size="pageSize"
        v-model:currentPage="current_page"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-card>
  <el-dialog v-model="examinebox" title="审核">
    <el-form :model="form" label-width="120px">
      <el-form-item label="审核状态">
        <el-radio-group v-model="examineData.status" class="ml-4">
          <el-radio label="1" size="large">通过</el-radio>
          <el-radio label="2" size="large">不通过</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="原因">
        <el-input v-model="examineData.reason" autocomplete="off" show-word-limit maxlength="50"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="setexamine">提交</el-button>
        <el-button @click="examinebox = false">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { ElMessage } from 'element-plus'
import api from '@/api/index.js'
export default defineComponent({
  setup() {
    let words = ref('')
    let examineData = ref({
      reason:'',
      id:'',
      status:"1",
    })
    let examinebox = ref(false)
    let page = ref(1)
    let pageSize = ref(10)
    let catebox = ref(false)
    let total = ref(0)
    let current_page = ref(1)
    const handleSelectionChange = () => {
      console.log('go back')
    }
    const tableData = ref([])

    return {
      examinebox,
      examineData,
      page,
      pageSize,
      current_page,
      tableData,
      catebox,
      total,
      words,
      handleSelectionChange
    };
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    setexamine(){
      let that = this
      api.memberApi.setexamine(that.examineData).then(res => {
          if (res.code === 0) {
            ElMessage({
              message: res.message,
              type: 'success',
            })
            that.examinebox = false
            that.getList()
          }else{
            ElMessage.error(res.message)
          }
      }).catch((res) => {
          ElMessage.error(res.message);
      })
    },
    examine(id){
      this.examinebox = true
      this.examineData.id = id
      this.examineData.reason = ''
      this.examineData.status = "1"
    },
    add(){
      this.$router.push({path:'/member/add'})
    },
    edit(id){
      this.$router.push({path:'/member/detail',query:{id:id}})
    },
    handleCurrentChange(val){
      this.page = val
      this.getList()
    },
    del(id){
      let that = this
      api.memberApi.del({id:id}).then(res => {
          if (res.code === 0) {
            ElMessage({
              message: res.message,
              type: 'success',
            })
            that.getList()
          }else{
            ElMessage.error(res.message)
          }
      }).catch((res) => {
          ElMessage.error(res.message);
      })
    },
    sreach(){
      this.page = 1
      this.getList()
    },
    getList(){
      let that = this
      api.memberApi.getList({words:that.words,page:that.page}).then(res => {
          if (res.code === 0) {
            that.pageSize = res.data.per_page
            that.tableData = res.data.data
            that.total = res.data.total
            that.current_page = res.data.current_page
          }else{
            ElMessage.error(res.message)
          }
      }).catch((res) => {
          ElMessage.error(res.message);
      })
    },
    EditLevel(info){
      this.title = '编辑职位'
      this.catebox = true
      this.formData.id = info.id
      this.formData.title = info.title
      this.formData.sort = info.sort
    },
    setLevel(){
      let that = this
      api.memberApi.setLevel(this.formData).then(res => {
          if (res.code === 0) {
              ElMessage({
                message: res.message,
                type: 'success',
              })
              that.catebox = false
              that.getList()
          }else{
            ElMessage.error(res.message)
          }
      }).catch((res) => {
          ElMessage.error(res.message);
      })
    }
  },
  mounted () {
    this.getList()
  }
})
</script>
<style>

</style>